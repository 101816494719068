import React, { useState } from "react";
import * as classes from "./index.module.less";
import { Link } from "gatsby";
import {
  InstantSearch,
  Pagination,
  Hits,
  Configure,
  SearchBox,
  Stats,
  Snippet,
  ScrollTo
} from "react-instantsearch-dom";
const algoliasearch = require("algoliasearch/lite");
const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

export default () => {
  const resultsPerPage = 8;
  const [searchValue, setSearchValue] = useState("");

  const Hit = ({ hit }) => {
    const croppedTitle = hit.title.length < 65?hit.title:hit.title.substring(0, 65) + "...";
    const croppedText = hit.text.substring(0, 200) + "...";
    return (
      <div className={classes.highlight}>
        <Link to={hit.url}>
          <p className={classes.highlightTitle}>
            {croppedTitle}
            {/* <Snippet attribute="title" hit={hit} /> */}
          </p>
          <p className={classes.highlightText}>
            {croppedText}
            {/* <Snippet attribute="text" hit={hit} highlightedTagName="em" /> */}
          </p>
        </Link>
      </div>
    );
  };
  return (
      <section className={classes.container}>
        <div className={classes.search} id={"searchBox"}>
          <h1 className={classes.title}>How can we help you?</h1>
        </div>
        <div className={classes.searchBar}>
          <InstantSearch
            onSearchStateChange={({ query }) => {
              if (query !== undefined) {
                setSearchValue(query);
              }
            }}
            indexName="AppInChina"
            searchClient={client}
          >
            <div className="left-panel">
              <Configure hitsPerPage={resultsPerPage} />
            </div>
            <div className="right-panel">
              <SearchBox />
              {searchValue !== "" ? (
                <ScrollTo scrollOn="page">
                  {/* <Hits/> */}
                  <Stats
                    translations={{
                      stats(nbHits, timeSpentMS, page) {
                        return (
                          <div className={classes.titleResults}>
                            <h2 className={classes.titleSearch}>Search Results </h2>
                            <p className={classes.titleNumber}>
                              ({nbHits} results)
                            </p>
                          </div>
                        );
                      },
                    }}
                  />
                  <Hits hitComponent={Hit} />
                  <Pagination scrollTo={"searchBox"} />
                </ScrollTo>
              ) : 
                <div style={{width:"100%", height:"200px", backgroundColor:"transparent"}}></div>
              }
            </div>
          </InstantSearch>
        </div>
      </section>
  );
};
