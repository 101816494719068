import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import Search from "components/shared/Search";

export default () => (
  <>
    <Meta url="/search/" />
    <Nav />
    <Search />
    <CTA />
    <Footer />
  </>
);
